import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1024.000000 1024.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g transform="translate(0.000000,1024.000000) scale(0.100000,-0.100000)"
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd">
<path d="M4614 7817 c-132 -140 -192 -283 -201 -477 -7 -168 25 -297 108 -436
36 -60 157 -194 175 -194 6 0 16 11 23 24 12 22 9 28 -45 84 -66 67 -114 142
-150 231 -33 82 -45 150 -45 257 0 189 63 343 198 485 48 50 53 60 43 78 -6
12 -17 21 -24 21 -7 0 -44 -33 -82 -73z"/>
<path d="M5600 7870 c-10 -18 -4 -29 46 -83 136 -146 197 -298 196 -488 0
-188 -68 -351 -202 -487 -49 -50 -52 -55 -40 -78 8 -13 18 -24 24 -24 22 0
135 130 180 206 69 117 97 215 103 354 10 223 -56 399 -211 558 -33 34 -66 62
-73 62 -7 0 -17 -9 -23 -20z"/>
<path d="M4767 7668 c-106 -125 -142 -229 -135 -392 6 -126 23 -181 87 -274
38 -55 108 -130 113 -121 2 2 12 14 22 26 18 22 18 23 -12 48 -42 36 -100 125
-122 191 -58 168 -21 345 99 476 46 50 48 54 33 76 -22 33 -36 28 -85 -30z"/>
<path d="M5468 7698 c-15 -22 -13 -26 35 -78 166 -181 165 -462 -2 -640 -49
-52 -49 -53 -33 -76 9 -13 20 -24 23 -24 17 0 117 122 143 175 42 86 56 148
57 245 0 102 -20 183 -66 265 -32 58 -116 155 -134 155 -5 0 -15 -10 -23 -22z"/>
<path d="M4922 7527 c-17 -17 -43 -59 -58 -91 -51 -112 -30 -260 50 -348 40
-44 48 -46 69 -14 15 22 15 25 -5 42 -35 32 -66 97 -74 151 -9 72 10 139 56
200 l38 51 -22 21 -22 21 -32 -33z"/>
<path d="M5342 7537 l-22 -24 25 -27 c91 -98 96 -253 12 -351 -32 -37 -33 -41
-20 -62 20 -31 30 -29 65 10 66 74 97 176 79 269 -12 69 -49 143 -88 181 l-29
27 -22 -23z"/>
<path d="M5103 7426 c-42 -19 -73 -70 -73 -121 0 -53 18 -85 63 -114 l37 -24
0 -163 0 -164 -40 -20 c-44 -23 -69 -55 -79 -102 -6 -30 -11 -32 -95 -55 -302
-80 -529 -324 -591 -634 -4 -22 -11 -27 -47 -32 -29 -4 -50 -14 -65 -32 -21
-24 -23 -36 -23 -158 0 -73 3 -142 6 -155 7 -26 51 -52 89 -52 l25 0 0 -468 0
-468 208 -270 c268 -348 327 -424 439 -569 51 -66 93 -123 93 -127 0 -5 -157
-8 -350 -8 l-350 0 0 -150 0 -150 65 0 c64 0 65 1 78 33 23 53 35 58 137 55
l92 -3 -116 -98 -116 -98 0 -53 0 -54 88 2 c71 1 93 -3 122 -20 68 -39 76
-114 17 -158 -24 -17 -43 -21 -110 -21 -69 0 -92 5 -144 29 -35 16 -68 35 -74
42 -24 29 -29 11 -29 -100 0 -110 1 -114 25 -130 68 -45 317 -62 453 -31 148
34 248 121 272 236 33 156 -44 282 -203 333 -16 5 -14 10 15 33 18 15 35 30
38 33 3 3 29 25 58 49 l52 43 0 74 c0 41 3 74 8 74 4 0 28 -27 52 -59 34 -45
52 -61 74 -63 27 -3 28 -5 30 -61 0 -31 -1 -57 -3 -57 -3 0 -13 8 -24 17 -20
18 -20 18 -3 -15 10 -19 16 -35 14 -37 -2 -1 -19 -12 -38 -24 -39 -23 -30 -29
10 -6 38 22 40 19 40 -41 0 -34 4 -53 10 -49 6 3 10 2 10 -4 0 -5 7 -17 16
-25 14 -15 14 -16 -1 -16 -9 0 -13 -5 -10 -10 4 -6 1 -9 -7 -8 -10 2 -13 -14
-14 -60 -2 -59 0 -64 23 -73 34 -13 48 -12 36 4 -6 6 -13 21 -16 32 -6 17 -4
16 14 -4 17 -21 22 -22 30 -10 5 7 9 21 9 29 0 8 5 22 10 30 10 15 7 -12 -7
-64 -4 -15 -1 -18 16 -13 18 4 20 3 10 -9 -14 -17 -12 -20 24 -29 28 -7 29 -7
25 30 -4 50 12 54 36 10 11 -19 21 -33 23 -31 3 2 -3 18 -12 36 -9 18 -14 34
-11 38 3 3 29 0 58 -6 73 -17 72 -7 -2 15 l-63 18 42 28 c52 35 53 44 1 17
-51 -26 -54 -25 -45 8 18 68 22 91 15 87 -5 -3 -11 -19 -15 -35 -14 -65 -25
-38 -25 63 0 84 -3 106 -15 111 -8 3 -15 10 -15 15 0 5 7 16 15 25 8 8 15 26
15 41 0 34 18 39 91 26 75 -13 93 -28 110 -89 14 -52 23 -62 57 -62 25 0 26 3
11 33 -9 20 -9 20 11 -2 24 -28 23 -29 39 27 11 38 10 46 -3 61 -22 25 -20 36
12 61 l27 21 -34 -17 c-35 -16 -35 -16 -59 12 -19 23 -22 24 -12 6 16 -33 8
-37 -32 -15 -27 15 -62 18 -197 21 -90 2 -161 0 -158 -5 3 -4 -8 -8 -25 -9
-20 -1 -25 2 -16 7 9 6 4 9 -14 9 -16 0 -28 2 -28 5 0 3 27 40 59 83 33 42
218 281 410 531 l351 455 0 468 0 468 25 0 c33 0 81 25 89 45 3 9 6 81 6 159
0 138 -1 143 -24 165 -13 13 -42 25 -65 28 -35 5 -42 10 -46 32 -3 14 -17 63
-32 109 -82 257 -293 455 -561 526 -83 22 -87 24 -93 56 -9 44 -38 80 -82 103
l-37 19 0 164 c0 161 0 165 23 173 44 17 70 57 74 113 2 31 -1 63 -9 77 -31
58 -114 85 -175 57z m95 -72 c45 -31 19 -114 -36 -114 -36 0 -62 27 -62 66 0
34 28 64 60 64 9 0 26 -7 38 -16z m142 -844 c216 -48 391 -205 471 -421 23
-61 24 -78 27 -366 l3 -303 -681 0 -682 0 4 303 c4 281 6 306 26 361 81 219
242 367 457 421 61 15 313 18 375 5z m-572 -1471 l2 -205 -82 4 c-64 3 -98 -1
-145 -16 l-63 -20 0 224 0 225 143 -3 142 -3 3 -206z m335 194 c-23 -2 -64 -2
-90 0 -26 2 -7 3 42 3 50 0 71 -1 48 -3z m130 0 c-13 -2 -35 -2 -50 0 -16 2
-5 4 22 4 28 0 40 -2 28 -4z m-26 -85 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7
2 -19 0 -25z m-24 -275 c-13 -2 -33 -2 -45 0 -13 2 -3 4 22 4 25 0 35 -2 23
-4z m-179 -24 c-3 -5 -14 -5 -25 1 -26 14 -24 18 6 12 14 -2 22 -8 19 -13z
m49 14 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m162 -8 c-23 -8
-133 -11 -129 -3 3 4 35 8 72 7 37 0 63 -2 57 -4z m-220 -25 c13 -6 15 -9 5
-9 -8 0 -22 4 -30 9 -18 12 -2 12 25 0z m-250 -194 c28 -13 74 -44 103 -70 30
-25 57 -46 62 -46 5 0 30 19 57 42 67 58 116 78 192 78 77 0 123 -20 200 -85
l53 -45 56 50 c66 59 143 100 187 100 32 0 93 -17 107 -29 4 -4 -81 -122 -190
-263 -108 -141 -206 -268 -217 -283 l-20 -26 -45 16 c-60 22 -190 22 -254 0
-26 -9 -52 -13 -55 -8 -17 20 -155 198 -231 298 -46 61 -98 128 -115 150 -17
22 -44 57 -60 77 l-27 38 33 15 c50 21 107 18 164 -9z"/>
<path d="M4730 6019 c-123 -55 -180 -190 -131 -305 69 -160 252 -203 380 -88
103 92 105 251 4 346 -40 38 -111 68 -163 68 -25 0 -65 -10 -90 -21z m163
-147 c11 -11 21 -39 24 -65 5 -41 2 -49 -25 -77 -24 -24 -39 -30 -72 -30 -102
0 -140 135 -52 185 35 20 99 13 125 -13z"/>
<path d="M5405 6016 c-161 -75 -190 -283 -55 -399 64 -55 150 -70 231 -42 162
58 208 278 82 397 -71 67 -172 85 -258 44z m172 -151 c78 -90 -47 -218 -140
-145 -51 41 -41 146 15 170 39 17 100 4 125 -25z"/>
<path d="M2397 3853 c-4 -3 -7 -57 -7 -120 l0 -113 38 0 c37 -1 38 -2 180
-200 l143 -200 -3 -98 -3 -97 -46 -3 -46 -3 -7 -46 c-3 -25 -6 -79 -6 -119 l0
-74 295 0 295 0 0 120 0 120 -50 0 -50 0 0 123 0 124 62 104 c33 57 80 136
103 174 l41 70 62 3 63 3 -3 117 -3 117 -210 0 -210 0 -3 -117 -3 -118 46 0
c25 0 45 -3 45 -6 0 -15 -86 -164 -95 -164 -8 0 -103 135 -113 161 -2 5 8 9
22 9 l26 0 0 120 0 120 -278 0 c-153 0 -282 -3 -285 -7z"/>
<path d="M6266 3705 c-18 -59 -27 -62 -52 -13 -8 16 -18 28 -20 25 -3 -3 2
-19 11 -37 9 -18 14 -34 11 -37 -3 -3 -28 0 -56 7 -70 17 -75 6 -6 -14 31 -9
56 -21 56 -26 0 -6 -3 -10 -7 -10 -5 0 -19 -10 -33 -21 -23 -18 -23 -20 -4
-14 24 6 36 -11 27 -43 -3 -15 3 -25 24 -37 15 -9 20 -14 9 -10 -12 3 -25 -2
-36 -15 -17 -20 -19 -20 -22 -3 -4 15 -7 13 -18 -9 -11 -23 -10 -31 4 -53 l17
-25 45 26 c58 34 69 24 12 -12 -23 -15 -44 -28 -46 -29 -2 -2 9 -26 23 -55 44
-86 19 -66 -35 29 -7 12 -14 11 -42 -5 -26 -16 -37 -33 -55 -84 -28 -83 -28
-82 2 -74 16 4 25 2 25 -5 0 -7 10 -16 23 -21 20 -9 20 -10 2 -9 -37 2 -45 -1
-46 -18 0 -15 -2 -15 -8 2 -5 11 -7 23 -4 28 2 4 -2 7 -10 7 -8 0 -18 -11 -21
-25 -7 -29 -23 -32 -43 -7 -13 15 -13 15 -4 -5 15 -30 15 -29 -26 -58 -42 -28
-38 -33 7 -10 32 16 38 8 20 -26 -7 -12 -21 -19 -39 -19 -30 0 -45 -19 -35
-45 4 -8 17 -15 31 -15 13 0 22 4 19 8 -3 5 8 9 24 9 16 0 28 -5 27 -11 -2
-12 103 -10 116 3 18 18 2 46 -28 51 -32 6 -34 14 -14 77 10 31 11 31 66 28
31 -2 66 1 77 7 12 6 18 8 15 4 -4 -4 2 -29 12 -57 17 -43 17 -50 4 -54 -8 -3
-22 -7 -31 -10 -9 -3 -14 -14 -12 -27 3 -21 10 -23 74 -28 39 -3 111 -3 160 0
87 5 89 6 89 30 0 20 -6 26 -27 28 -23 3 -30 13 -53 73 -27 71 -27 106 0 95 8
-3 15 0 15 6 0 6 8 17 18 25 15 13 15 13 -6 3 -12 -6 -27 -8 -32 -5 -6 3 -10
-1 -10 -10 0 -29 -24 -10 -39 31 -35 97 -50 124 -65 118 -9 -3 -16 -14 -17
-23 0 -9 -5 -3 -11 13 -9 24 -8 31 6 41 23 17 22 25 -26 170 -22 68 -23 64 19
54 61 -14 82 -9 33 7 -25 8 -51 17 -59 20 -10 3 -3 13 22 30 49 33 47 40 -3
14 -44 -23 -46 -21 -29 49 14 64 4 62 -15 -4z m-70 -459 c15 -48 21 -55 54
-65 50 -15 52 -14 40 8 -16 31 -11 38 9 14 l18 -22 13 42 c7 23 15 36 17 29 3
-7 0 -26 -6 -42 -11 -33 -4 -37 32 -19 22 10 22 10 0 -10 -30 -25 -29 -28 7
-36 16 -4 32 -10 35 -15 3 -4 -12 -4 -32 1 -44 9 -48 7 -34 -19 17 -32 13 -39
-6 -12 l-17 24 -18 -39 -19 -40 7 44 c6 42 5 43 -17 36 -23 -6 -23 -5 -5 9 30
24 12 31 -79 31 -54 0 -81 4 -76 10 7 9 17 33 40 98 6 15 13 27 15 27 3 0 13
-24 22 -54z m220 -251 l29 17 -25 -22 c-14 -13 -24 -25 -23 -29 1 -3 -12 -7
-29 -7 -24 -1 -27 1 -14 8 12 7 15 16 10 31 -6 21 -5 21 8 3 14 -18 16 -18 44
-1z"/>
<path d="M7694 3706 c-10 -31 -21 -56 -24 -56 -3 0 -16 18 -29 40 -12 22 -24
40 -26 40 -5 0 10 -43 26 -72 11 -20 9 -21 -22 -15 -96 17 -101 16 -41 -5 34
-12 62 -24 62 -28 0 -3 -16 -14 -35 -24 -19 -11 -35 -23 -35 -28 0 -5 9 -4 19
2 27 14 30 13 28 -10 -1 -11 -5 -20 -9 -20 -4 0 -8 -5 -8 -11 0 -5 5 -7 10 -4
6 3 10 0 10 -9 0 -8 12 -19 28 -25 l27 -11 -28 5 c-21 4 -27 2 -21 -6 5 -9 0
-10 -18 -6 -19 5 -28 2 -32 -9 -9 -25 -7 -49 7 -68 12 -16 16 -15 60 10 62 34
73 25 12 -10 -25 -15 -45 -32 -45 -39 0 -7 12 -32 25 -56 36 -62 22 -64 -16
-2 -18 28 -34 51 -36 51 -3 0 -17 -7 -33 -16 -21 -13 -34 -35 -53 -91 l-25
-74 29 6 c18 4 29 3 29 -5 0 -6 10 -15 23 -21 20 -8 19 -9 -6 -4 -17 4 -26 2
-23 -4 3 -5 -2 -8 -11 -7 -9 2 -19 8 -21 14 -6 20 -32 13 -32 -7 0 -31 -19
-35 -40 -9 -23 28 -26 17 -5 -16 15 -23 15 -25 -20 -46 -43 -27 -46 -36 -4
-15 17 9 33 15 35 12 9 -9 -17 -59 -29 -54 -6 2 -22 0 -35 -4 -35 -13 -26 -53
12 -57 28 -3 29 -2 15 15 -13 17 -13 17 4 4 14 -11 23 -12 44 -2 23 11 25 10
13 -3 -11 -14 -6 -16 48 -16 44 0 61 4 66 15 10 26 -5 45 -36 45 -28 0 -29 2
-22 33 15 70 15 70 85 71 60 2 119 24 119 46 0 12 -40 20 -104 20 -42 0 -57 3
-54 13 3 6 13 36 23 66 16 50 19 53 31 36 8 -10 17 -34 20 -53 5 -26 14 -37
40 -48 48 -20 55 -18 48 14 l-6 27 18 -24 18 -25 13 40 13 39 -5 -43 -5 -43
32 16 33 17 -29 -25 -29 -24 48 -12 c58 -15 54 -25 -5 -12 l-42 9 6 -26 c6
-24 5 -25 -7 -9 -19 24 -24 22 -32 -13 -4 -16 -10 -32 -15 -35 -4 -3 -4 12 1
32 4 21 7 39 5 40 -2 2 -15 -1 -29 -6 -24 -9 -25 -13 -15 -43 23 -74 22 -78
-6 -78 -22 0 -26 -4 -26 -30 l0 -30 86 0 c56 0 83 4 79 10 -4 6 -16 8 -28 4
-21 -6 -21 -6 -3 8 12 10 16 20 11 29 -6 11 -5 11 5 2 10 -9 18 -9 34 -1 20
10 20 10 5 -8 -28 -33 -13 -44 61 -44 51 0 69 4 74 15 9 24 -4 44 -31 47 -21
3 -30 15 -55 77 -30 77 -27 109 8 80 13 -10 16 -10 10 -1 -4 7 -2 20 6 29 10
13 10 15 0 9 -7 -4 -19 -3 -26 3 -11 9 -14 8 -12 -5 0 -9 -5 -19 -11 -21 -7
-3 -13 2 -13 11 0 9 -5 16 -12 16 -6 0 -9 3 -5 6 6 6 -30 113 -43 127 -4 5
-14 2 -21 -6 -11 -10 -17 -11 -24 -3 -18 21 -16 38 5 43 25 7 25 26 0 82 -11
23 -18 47 -15 52 3 4 -3 27 -15 50 -11 24 -18 44 -17 46 2 1 19 -2 38 -7 56
-16 78 -10 27 8 -26 9 -49 20 -53 23 -3 4 10 17 30 29 20 12 34 25 31 27 -3 3
-21 -5 -41 -17 -20 -12 -38 -20 -40 -18 -2 2 2 28 10 58 19 72 11 76 -11 6z"/>
<path d="M7225 3681 c-9 -28 -20 -51 -24 -51 -3 0 -15 16 -25 35 -25 45 -32
39 -10 -9 10 -19 15 -38 13 -41 -3 -2 -29 2 -59 10 -65 18 -63 17 -56 6 3 -5
27 -15 53 -22 47 -14 43 -14 -104 -17 -84 -1 -153 -5 -153 -8 0 -5 31 -56 40
-64 7 -7 12 -40 5 -40 -3 0 -19 23 -36 50 -30 49 -31 50 -57 36 -15 -9 -43
-22 -62 -31 -34 -15 -34 -15 -11 2 74 53 74 53 -19 50 -85 -2 -85 -2 -88 -29
-3 -23 0 -27 15 -22 17 5 17 5 0 -9 -13 -11 -17 -22 -12 -38 5 -14 4 -19 -4
-15 -8 5 -11 -11 -11 -53 0 -51 3 -61 20 -66 28 -9 36 -1 55 55 25 75 49 100
96 100 l39 0 0 -75 c0 -43 -4 -75 -10 -75 -5 0 -10 4 -10 8 0 5 -7 17 -15 28
-13 16 -13 13 1 -15 16 -33 16 -35 -7 -52 -13 -10 -28 -19 -33 -19 -5 0 -7 -3
-4 -6 4 -3 20 2 37 10 16 9 32 16 36 16 3 0 5 -25 5 -55 0 -49 2 -55 21 -55
11 0 29 -5 41 -11 26 -14 31 -8 18 27 l-10 29 17 -24 17 -24 18 39 18 39 -6
-43 -6 -44 31 16 c28 15 31 21 31 61 0 34 -5 47 -19 55 -25 13 -45 5 -59 -23
-11 -20 -11 -20 -6 5 5 20 2 30 -10 34 -15 6 -15 8 1 19 11 8 22 10 29 4 6 -5
15 -5 19 -1 5 4 5 2 2 -4 -6 -11 12 -24 34 -24 5 0 9 38 9 85 0 59 4 85 12 85
19 0 78 -24 78 -31 0 -5 19 -20 43 -34 23 -15 32 -23 19 -20 -22 6 -23 5 -16
-32 8 -49 17 -57 49 -49 l26 7 -7 94 c-4 52 -4 95 -1 95 4 0 14 -14 22 -31 9
-17 19 -29 21 -26 3 3 -2 19 -11 37 -9 18 -14 34 -11 37 3 3 28 0 56 -7 72
-17 71 -8 -2 17 l-62 20 42 28 c51 34 49 45 -2 14 -20 -12 -39 -19 -43 -16 -3
4 0 27 6 53 16 64 7 67 -14 5z m-274 -131 c24 13 24 13 5 -8 -11 -13 -16 -28
-12 -39 12 -32 -29 18 -42 52 l-13 30 19 -24 c17 -21 22 -22 43 -11z"/>
<path d="M7797 3521 c3 -11 12 -26 21 -33 13 -11 14 -11 7 1 -5 9 0 20 16 33
24 19 24 19 1 8 -17 -8 -27 -8 -37 0 -11 9 -12 7 -8 -9z"/>
<path d="M6373 3520 c1 -5 -7 -16 -18 -25 -20 -16 -19 -16 3 -5 12 6 27 8 32
5 6 -3 10 0 10 8 0 8 -7 17 -15 21 -8 3 -14 1 -12 -4z"/>
<path d="M3756 3509 c-61 -9 -124 -35 -163 -68 -89 -75 -84 -222 12 -298 19
-15 83 -55 142 -88 99 -56 108 -63 111 -93 9 -87 -135 -41 -170 55 l-12 33
-72 0 -73 0 -3 -135 -3 -135 63 0 c57 0 63 2 74 25 6 14 14 25 18 25 4 0 33
-12 65 -26 76 -35 205 -44 287 -20 146 42 218 169 173 305 -20 62 -77 115
-167 157 -146 68 -168 85 -155 118 15 39 125 4 149 -49 10 -23 16 -25 80 -25
l68 0 0 105 0 105 -60 0 c-45 0 -61 -4 -69 -17 -8 -16 -11 -16 -47 0 -82 35
-141 42 -248 26z"/>
<path d="M5741 3439 c-19 -54 -16 -62 19 -44 24 13 28 19 21 33 -26 44 -28 45
-40 11z"/>
<path d="M5759 3382 l-22 -19 33 -11 c18 -7 34 -11 36 -10 5 6 -9 58 -16 58
-4 0 -18 -8 -31 -18z"/>
<path d="M6120 3370 c-14 -27 -5 -39 15 -19 9 9 11 19 6 28 -7 11 -11 9 -21
-9z"/>
<path d="M7546 3374 c-10 -25 -7 -34 9 -28 17 7 20 30 6 38 -5 3 -12 -1 -15
-10z"/>
<path d="M5630 3345 l0 -26 32 17 c30 16 31 18 13 25 -35 13 -45 10 -45 -16z"/>
<path d="M5658 3324 c-26 -14 -28 -19 -28 -77 0 -94 -25 -197 -52 -216 -53
-38 -168 -57 -168 -27 0 8 -14 16 -32 19 -40 6 -42 4 -28 -22 6 -12 7 -21 1
-21 -5 0 -11 7 -15 15 -8 22 -26 19 -26 -4 0 -11 -4 -23 -10 -26 -31 -19 3
-25 141 -25 143 0 153 1 205 27 31 15 67 40 80 56 18 22 28 27 36 19 8 -8 7
-16 -3 -28 -10 -13 -10 -16 -1 -10 7 4 20 2 28 -5 13 -11 15 -10 12 3 -1 10 6
24 17 33 19 16 19 16 -4 5 -19 -10 -26 -9 -39 4 -15 15 -15 18 1 45 22 37 21
45 0 37 -16 -5 -16 -5 0 8 14 11 17 21 11 38 -6 20 -5 21 8 10 13 -10 16 -8
22 19 8 43 7 101 -2 116 -4 7 -23 16 -41 19 -32 7 -33 6 -26 -17 7 -23 6 -23
-11 -7 -17 17 -19 16 -36 -20 -17 -35 -18 -26 -3 26 7 26 0 27 -37 6z m6 -285
c-3 -5 1 -9 8 -10 7 0 3 -5 -8 -11 -12 -5 -27 -20 -33 -32 -10 -19 -11 -19 -6
-1 2 11 0 27 -5 35 -12 20 6 39 30 33 11 -3 17 -9 14 -14z"/>
<path d="M5110 3270 l-25 -21 28 13 c23 10 30 9 38 -3 10 -16 12 -8 3 15 -8
21 -14 20 -44 -4z"/>
<path d="M6717 3267 c3 -5 -2 -14 -11 -20 -14 -10 -12 -12 13 -13 19 -1 28 3
25 11 -3 6 5 16 18 22 20 9 18 10 -14 9 -20 -1 -34 -5 -31 -9z"/>
<path d="M6987 3209 c-21 -12 -13 -29 14 -29 12 0 19 7 19 20 0 22 -7 24 -33
9z"/>
<path d="M6828 3108 l-3 -103 -38 -3 c-35 -3 -38 -6 -35 -30 l3 -27 164 -3
c126 -2 166 0 172 10 6 10 12 8 24 -7 15 -18 15 -17 1 12 -16 31 -15 32 17 58
l32 26 -32 -15 c-43 -21 -48 -20 -62 10 -6 15 -15 24 -18 20 -4 -3 -1 -12 5
-18 20 -20 14 -38 -13 -38 -24 0 -24 2 -27 83 -3 82 -3 82 -31 85 -26 3 -27 2
-18 -17 17 -31 12 -38 -9 -13 l-19 23 -11 -41 c-15 -54 -24 -51 -15 6 6 43 5
45 -14 35 -31 -17 -38 -12 -12 8 l24 18 -33 12 c-18 6 -37 11 -41 11 -5 0 -10
-46 -11 -102z"/>
<path d="M7163 3120 c2 -8 -3 -21 -12 -28 -14 -12 -14 -13 3 -8 10 4 24 2 31
-4 10 -9 12 -7 8 8 -3 14 1 22 17 27 12 4 19 9 17 12 -3 2 -15 -1 -27 -7 -18
-10 -24 -9 -32 1 -8 11 -9 11 -5 -1z"/>
<path d="M5230 3035 c0 -28 5 -30 40 -10 l25 13 -28 11 c-38 15 -37 15 -37
-14z"/>
<path d="M5254 3005 c-10 -8 -33 -15 -50 -15 -32 0 -45 -14 -37 -37 3 -8 24
-13 58 -13 43 0 55 4 59 18 2 9 7 27 11 40 8 26 -11 30 -41 7z"/>
</g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
